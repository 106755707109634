.right-sidebar {
    height: 95vh !important;
    padding: 15px 10px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
}
.notfications-content::-webkit-scrollbar {
    width: 3px;
}
.notfications-content::-webkit-scrollbar-thumb {
    background-color: rgb(226, 226, 226);
    border-radius: 10px;
}
.notfication-box {
    transform: translateX(10px);
    opacity: 0;
}
.notfication-box.active {
    animation: mounted-animate .4s 1 both linear;
}
@keyframes mounted-animate {
    from {
        transform: translateX(10px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}