.message-row td {
    text-align: left !important;
}
.messages-box::-webkit-scrollbar {
    width: 2px;
    border-radius: 50%;
}
.messages-box::-webkit-scrollbar-thumb {
    background-color: rgb(214, 214, 214);
}
.contacts-table td {
    padding: 10px !important;
}