.file-upload-form {
    /* width: fit-content; */
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .file-upload-label input {
    display: none;
  }
  .file-upload-label svg {
    height: 50px;
    fill: rgb(255, 77, 0);
    margin-bottom: 20px;
  }
  .file-upload-label {
    cursor: pointer;
    background-color: #f94700b5;
    padding: 30px 70px;
    border-radius: 40px;
    border: 2px dashed rgb(49, 46, 42);
    box-shadow: 0px 0px 200px -50px rgba(255, 73, 1, 0.719);
  }
  .file-upload-design {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .browse-button {
    background-color: rgb(36, 35, 43);
    padding: 5px 15px;
    border-radius: 10px;
    color: white;
    transition: all 0.3s;
  }
  .browse-button:hover {
    background-color: rgb(14, 14, 14);
  }
  